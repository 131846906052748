import {EventResource} from './event-resource.class';
import {TicketType} from './ticket-type.class';
import {ModelBase} from './abstract/model-base';
import {ExternalData} from './external-data.class';
import {Timezone} from './timezone.class';
import {mapTranslation, Translation} from './translation';
import {Venue} from './venue.class';
import moment from 'moment';
import {isNullOrUndefined} from '../common';

export class Event extends ModelBase {
    public id: string = null;
    public status = 'ONLINE';
    public venueId: string = null;
    public eventCategoryId: string = null;
    public categoryId: string = null;
    public analyticsDashboardId: string = null;
    public pricePlanId: string = null;
    public name: string = null;
    public nameTranslation: Translation = null;
    public description: string = null;
    public descriptionTranslation = null;
    public riskCategory: string = null;
    public timezone: Timezone;
    public startAt: string = null;
    public endAt: string = null;
    public initialized = false;
    public initializeFailed = false;
    public type = 'EVENT';
    public resources: EventResource[] = [];
    public ticketTypes: TicketType[] = [];
    public externalData: ExternalData[] = [];
    public isDeleted = false;
    public venueName: string = null;
    public eventCategoryName: string = null;

    public blockades: any[] = [];
    public copyBlockade = null;

    // Not persisted data
    public viewData = [];
    public venue: Venue = null;

    public _untilStartAtInMinutes: number = null;

    public _startAtFormatted: string = null;
    public _endAtFormatted: string = null;

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/event',
            getMany: 'v1.0/event',
            get: 'v1.0/event/:id',
            patch: 'v1.0/event/:id',
            delete: 'v1.0/event/:id'
        });
    }

    public static empty(): Event {
        const event = new Event();

        event.timezone = Timezone.empty();
        event.nameTranslation = Translation.empty();
        event.descriptionTranslation = Translation.empty();
        event.resources = [EventResource.empty(), EventResource.empty()];

        return event;
    }

    mapModel(data: any): Event {
        let eventResources: EventResource[] = [];

        if (data.resources) {
            eventResources = [];
            for (const resource of data.resources) {
                if (resource) {
                    switch (resource.type) {
                        case 'HOME_LOGO': {
                            eventResources[0] = resource;
                            break;
                        }

                        case 'AWAY_LOGO': {
                            eventResources[1] = resource;
                            break;
                        }
                    }
                }
            }

            if (data.type !== 'MATCH') {
                eventResources[1] = null;
            }
        }

        const model = new Event();
        model.id = data.id;
        model.status = data.status;
        model.timezone = data.timezone;
        model.venueId = data.venueId;
        model.eventCategoryId = data.categoryId || data.eventCategoryId;
        model.categoryId = data.categoryId || data.eventCategoryId;
        model.analyticsDashboardId = data.analyticsDashboardId;
        model.pricePlanId = data.pricePlanId;
        model.name = data.name;
        model.nameTranslation = data.nameTranslation ? mapTranslation(data.nameTranslation) : Translation.empty();
        model.description = data.description;
        model.descriptionTranslation = data.descriptionTranslation ? mapTranslation(data.descriptionTranslation) : Translation.empty();
        model.riskCategory = data.riskCategory;
        model.startAt = data.startAt;
        model.endAt = data.endAt;
        model.initialized = data.initialized;
        model.initializeFailed = data.initializeFailed;
        model.type = data.type;
        model.resources = eventResources;
        model.ticketTypes = data.ticketTypes;
        model.externalData = data.externalData ? data.externalData : [];
        model.viewData = null;
        model.isDeleted = data.isDeleted;
        model.venueName = data.venueName;
        model.eventCategoryName = data.eventCategoryName;

        model.blockades = data.blockades;

        model._startAtFormatted = moment(data.startAt).format('ll');
        model._endAtFormatted = moment(data.endAt).format('ll');

        return model;
    }

    getTicketTypeWithPrice(venueSectionGroupId: string): TicketType {
        return this.ticketTypes.find(ticketType => {
            const priceItem = ticketType.prices.find(price => price.venueSectionGroupId === venueSectionGroupId);
            return !isNullOrUndefined(priceItem?.price);
        });
    }

    getResource(type): string {
        const resource = this.resources.find(resourceItem => {
            if (resourceItem) {
                return resourceItem.type === type;
            }
        });

        if (resource && resource.resource) {
            return resource.resource.link;
        }

        return null;
    }

    isUpcoming(): boolean {
        return (new Date(this.endAt) > new Date());
    }
}

export function mapEvent(item: any): Event {
    return new Event().mapModel(item);
}
