import {ModelBase} from './abstract/model-base';

export class Timezone extends ModelBase {
    constructor(public id: string = null,
                public name: string = null,
                public abbreviation: string = null,
                public countryId: string = null,
                public utcOffset: string = null)
    {
        super();

        super.setRoutes({
            getMany: 'v1.0/timezone'
        });
    }

    public static empty(): Timezone {
        return new Timezone();
    }

    mapModel(data: any) {
        const model = new Timezone();

        model.id = data.id;
        model.name = data.name;
        model.abbreviation = data.abbreviation;
        model.countryId = data.countryId;
        model.utcOffset = data.utcOffset;

        return model;
    }
}

export function mapTimezone(item: any): Timezone {
    return new Timezone().mapModel(item);
}
