import {ModelBase} from './abstract/model-base';

export class DownloadSetting extends ModelBase {
    public id: string              = null;
    public isDownloadEnabled       = true;
    public downloadStartAt: string = null;

    _downloadableLabel = 'General.No';

    constructor() {
        super();
        super.setRoutes({
            post:    'v1.0/downloadsetting',
            getMany: 'v1.0/downloadsetting',
            get:     'v1.0/downloadsetting/:id',
            patch:   'v1.0/downloadsetting/:id',
            delete:  'v1.0/downloadsetting/:id'
        });
    }

    mapModel(data: any): DownloadSetting {
        const model             = new DownloadSetting();
        model.id                = data.id;
        model.isDownloadEnabled = data.isDownloadEnabled;
        model.downloadStartAt   = data.downloadStartAt;

        return model;
    }
}
