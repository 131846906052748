/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
export const shortenLargeNumber = (amount, digits) => {
    const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    let decimal;

    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (amount <= -decimal || amount >= decimal) {
            return +(amount / decimal).toFixed(digits) + units[i];
        }
    }

    return amount;
};

export const isEmpty = (value: any) => value == null || value.length === 0;
export const isNullOrUndefined = (value: any) => value === null || value === undefined;
