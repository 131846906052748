export class EventResource {
    constructor(public type: string,
                public resource: Resource) {
    }

    public static empty(): EventResource {
        return new EventResource(null, Resource.empty());
    }
}

export function mapEventResource(item: any): EventResource {
    return new EventResource(
        item.type,
        item.resource
    );
}

export class Resource {
    constructor(public type: string,
                public uuid: string,
                public name: string,
                public link: any,
                public updatedAt?: any) {
    }

    public static empty(): Resource {
        return new Resource(null, null, null, null, null);
    }
}

export function mapResource(item: any): Resource {
    return new Resource(
        item.type,
        item.uuid,
        item.name,
        item.link,
        item.updatedAt
    );
}
