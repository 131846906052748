import {PriceItem} from './price-item.class';
import {ExternalData} from './external-data.class';
import {DownloadSetting} from './download-setting.class';
import {mapTranslation, Translation} from './translation';

export class SubscriptionTypePrice {

    _isDownloadEnabled: boolean;

    constructor(public id: string = null,
                public subscriptionTypeId: string = null,
                public resellSubscriptionTypePriceId: string = null,
                public name: string = null,
                public nameTranslation: Translation = Translation.empty(),
                public subtitle: string = null,
                public subtitleTranslation: Translation = Translation.empty(),
                public order: number = null,
                public isOnline: boolean = true,
                public isPersonalisationRequired: boolean = false,
                public isC3vEnabled: boolean = false,
                public isOnlyResellCapacity: boolean = false,
                public minAge: number = null,
                public maxAge: number = null,
                public maxAmount: number = null,
                public priceItems: PriceItem[] = [],
                public externalData: ExternalData[] = [],
                public downloadSetting: DownloadSetting = null,
                public isResellEnabled: boolean = false,
                public bookingFeeAmount: number = 0) {
    }
}

export function mapSubscriptionTypePrice(item: any): SubscriptionTypePrice {
    return new SubscriptionTypePrice(
        item.id,
        item.subscriptionTypeId,
        item.resellSubscriptionTypePriceId,
        item.name,
        item.nameTranslation ? mapTranslation(item.nameTranslation) : Translation.empty(),
        item.subtitle,
        item.subtitleTranslation ? mapTranslation(item.subtitleTranslation) : Translation.empty(),
        item.order,
        item.isOnline,
        item.isPersonalisationRequired,
        item.isC3vEnabled,
        item.isOnlyResellCapacity,
        item.minAge,
        item.maxAge,
        item.maxAmount,
        item.priceItems,
        item.externalData ? item.externalData : [],
        item.downloadSetting ? (new DownloadSetting()).mapModel(item.downloadSetting) : null,
        item.isResellEnabled,
        item.bookingFeeAmount
    );
}
