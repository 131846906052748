export class Translation {
    constructor(
        public id: string = null,
        public key: string = null,
        public values: any
    ) {
    }

    public static empty(): Translation {
        return new Translation(null, null, {
            EN: null,
            NL: null,
            DE: null,
            FR: null
        });
    }

    public isEmpty(): boolean {
        return Object.values(this.values).every(value => {
            return value === null || value === '';
        });
    }

    public getSingleTranslation(): string {
        return this.values.EN || this.values.NL || this.values.DE || this.values.FR;
    }
}

export function mapTranslation(item: any) {
    return new Translation(
        item.id,
        item.key,
        item.values
    );
}
